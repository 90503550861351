import { createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../helpers/handleApiCall';
import { commonService } from '../../services/commonServiceInstance';

const initialState = {
    optedOutNumbers: {},
};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationInternal(state, action) {
            state.notification = action.payload;
        },
        setOptedOutNumbers(state, action) {
            state.optedOutNumbers = action.payload;
        },
    },
});

export const { setNotificationInternal, setOptedOutNumbers } = slice.actions;
export const notifications = { reducer: slice.reducer, initialState };
export default notifications;

// Thunks (side effects)
export const setNotification = (notification) => (dispatch) => {
    let message, options;
    if (typeof notification === 'string' || notification instanceof String) message = notification;
    else ({ message, options } = notification);
    // timestamp is used to differentiate between notifications with the same
    // content but are different notifications
    notification = { message, options, timestamp: Date.now() };

    dispatch(setNotificationInternal(notification));
};

export const checkForOptOut = (phoneNumber) => async (dispatch, getState) => {
    if (!phoneNumber) return;

    const cacheKey = phoneNumber?.replace(/\D/g, '');
    const { optedOutNumbers } = getState().notifications;

    if (optedOutNumbers[cacheKey]) return optedOutNumbers[cacheKey];

    const { wasSuccessful, isOptedOut } = await handleApiCall(
        async () => await commonService.isPhoneNumberOptedOut({ phoneNumber: cacheKey }),
        {
            dispatch,
        },
    );

    if (!wasSuccessful) return;

    const newOptedOutNumbers = { ...optedOutNumbers, [cacheKey]: isOptedOut };

    await dispatch(setOptedOutNumbers(newOptedOutNumbers));

    return isOptedOut;
};
