import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import { decodeToken, getCookieDomain } from '@cc/common';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearToken,
    finalizeLogout,
    setExpiryTimeoutToken,
    clearExpiryTimeoutToken,
    setLoggedOutInactive,
    setFriendlyKickoutMessage,
    clearFriendlyKickoutMessage,
} from 'redux/slices/auth';
import { Redirect } from 'react-router-dom';

const domain = getCookieDomain();
const tokenKey = `Token${window.environment.ENVIRONMENT}`;
const portalKey = `Portal${window.environment.ENVIRONMENT}`;

// Redirect to Portal when existing token
if (window.location.href.toLowerCase().indexOf('/logout') < 0) {
    const cookieToken = Cookies.get(tokenKey, { domain });
    const cookiePortal = Cookies.get(portalKey, { domain });
    if (cookieToken && cookiePortal) {
        const decoded = decodeToken(cookieToken, { complete: true });
        const hasToken = !!decoded?.payload?.exp;
        const isTokenExpired = hasToken && new Date(decoded.payload.exp * 1000) < new Date();
        if (!isTokenExpired) {
            switch (cookiePortal) {
                case 'provider':
                    console.log('Token invalid, redirecting to Provider Portal');
                    window.location = window.environment.PROVIDER_PORTAL_URL;
                    break;
            }
        }
    }
}

export const TokenWatcher = () => {
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [redirectMessage, setRedirectMessage] = useState(null);

    const dispatch = useDispatch();
    const { token, expiryTimeoutToken, changePasswordId } = useSelector((s) => s.auth);

    const { userEnrollmentToken } = useSelector((s) => s.userEnrollment);

    useEffect(() => {
        const friendlyKickoutMessages = {
            'user-enrollment': 'Please click the enrollment email link to log back in.',
            'change-password':
                'Please click the change password email link to change your password.',
        };

        if (window.location.pathname === '/sso/user-enrollment' && userEnrollmentToken) {
            dispatch(setFriendlyKickoutMessage(friendlyKickoutMessages['user-enrollment']));
        }

        if (window.location.pathname === '/sso/change-password' && changePasswordId) {
            dispatch(setFriendlyKickoutMessage(friendlyKickoutMessages['change-password']));
        }

        if (token) {
            if (expiryTimeoutToken) {
                clearTimeout(expiryTimeoutToken);
            }

            const decoded = decodeToken(token, { complete: true });

            const expiryTimeMs = decoded.payload.exp * 1000;

            const date = new Date();

            const timeNowMs = date.getTime();

            const msUntilExpiry = expiryTimeMs - timeNowMs;

            if (msUntilExpiry > 0) {
                const timeoutToken = setTimeout(() => {
                    dispatch(clearToken());
                    dispatch(clearExpiryTimeoutToken());
                    dispatch(finalizeLogout());
                    dispatch(setLoggedOutInactive(true));
                    setShouldRedirect(true);
                }, msUntilExpiry);

                dispatch(setExpiryTimeoutToken(timeoutToken));
            }
        }
    }, [token, userEnrollmentToken]);

    useEffect(() => {
        if (shouldRedirect) {
            setShouldRedirect(false);
        }
    }, [shouldRedirect]);

    return <>{shouldRedirect && <Redirect to="/" />}</>;
};
