import { createSlice } from '@reduxjs/toolkit';
import { PatientDocumentAssociationType } from '../../../enums/PatientDocumentAssociationType';
import { commonService } from '../../../services/commonServiceInstance';
import { handleApiCall } from '../../../helpers/handleApiCall';

// NOTE: because this component can be used more than once one
// the same page, each "instanceName" in this redux slice will have this
// initial state when init() is called.
const initialState = {
    chartAssociationType: null,
    isChartLoaded: false,
    chartMetadata: null,
    page: null,
    chartText: null,
    hasError: false,
};

const slice = createSlice({
    name: 'chartViewer',
    initialState: {},
    reducers: {
        init(state, action) {
            const instanceName = action.payload;
            if (state[instanceName]) return;
            state[instanceName] = { ...initialState };
        },
        reset(state, action) {
            const instanceName = action.payload;
            state[instanceName] = { ...initialState };
        },
        setIsChartLoaded(state, action) {
            const { instanceName, payload } = action.payload;
            state[instanceName].isChartLoaded = payload;
        },
        setChartMetadata(state, action) {
            const { instanceName, payload } = action.payload;
            state[instanceName].chartMetadata = payload;
        },
        setPage(state, action) {
            const { instanceName, payload } = action.payload;
            state[instanceName].page = payload;
        },
        setChartText(state, action) {
            const { instanceName, payload } = action.payload;
            state[instanceName].chartText = payload;
        },
        setHasError(state, action) {
            const { instanceName, payload } = action.payload;
            state[instanceName].hasError = payload;
        },
    },
});

export const {
    init,
    reset,
    setIsChartLoaded,
    setChartMetadata,
    setPage,
    setChartText,
    setHasError,
} = slice.actions;
export default { reducer: slice.reducer, initialState: {} };

// Thunks (side effects)

export const loadChart =
    ({ chartId, chartAssociationType, instanceName }) =>
    async (dispatch) => {
        await dispatch(init(instanceName));

        if (chartAssociationType === PatientDocumentAssociationType.Document) {
            await dispatch(loadChartMetadata(chartId, instanceName));
        } else {
            // if is demographics or appointments, a virtual chart which doesn't need metadata
            await dispatch(loadVirtualChart(chartId, instanceName));
        }
    };

const loadChartMetadata = (chartId, instanceName) => async (dispatch) => {
    if (!chartId) return;

    const result = await handleApiCall(
        async () =>
            await commonService.getDocumentInfo({
                patientDocumentId: chartId,
            }),
        { dispatch },
    );
    const { wasSuccessful, pageCount, supportedFormats } = result;
    const metadata = {
        supportedFormats,
        pageCount,
    };

    await dispatch(setHasError({ instanceName, payload: !wasSuccessful }));
    await dispatch(setChartText({ instanceName, payload: null }));
    await dispatch(setPage({ instanceName, payload: 1 }));
    await dispatch(setChartMetadata({ instanceName, payload: metadata }));
    await dispatch(setIsChartLoaded({ instanceName, payload: { chartId } }));
};

const loadVirtualChart = (chartId, instanceName) => async (dispatch, getState) => {
    await dispatch(setHasError({ instanceName, payload: false }));
    await dispatch(setChartText({ instanceName, payload: null }));
    await dispatch(setPage({ instanceName, payload: 1 }));
    await dispatch(setIsChartLoaded({ instanceName, payload: { chartId } }));
};

export const loadChartText =
    ({ chartAssociationType, instanceName, id, returnRawData }) =>
    async (dispatch, getState) => {
        if (!id) return;

        const result = await handleApiCall(
            async () => {
                if (chartAssociationType === PatientDocumentAssociationType.Document)
                    return await commonService.getDocumentText({
                        patientDocumentId: id,
                        returnRawData,
                    });
                if (chartAssociationType === PatientDocumentAssociationType.Appointment)
                    return await commonService.getAppointmentText({
                        appointmentId: id,
                    });
                if (chartAssociationType === PatientDocumentAssociationType.Demographic)
                    return await commonService.getDemographicsText({ patientId: id });
                if (chartAssociationType === PatientDocumentAssociationType.SummaryOfCare)
                    return await commonService.getSummaryOfCareText({ patientId: id });
                if (chartAssociationType === PatientDocumentAssociationType.Referral)
                    return await commonService.getReferralText({ referralId: id });

                throw new Error(`Unsupported chartAssociationType: ${chartAssociationType}`);
            },
            { dispatch },
        );

        if (!result.wasSuccessful) {
            await dispatch(setHasError({ instanceName, payload: true }));
            return;
        }

        await dispatch(setChartText({ instanceName, payload: result }));
    };
