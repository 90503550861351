import jwtDecoder from 'jwt-decode';
import jws from 'jws';
import Cookies from 'js-cookie';
import differenceInMinutes from 'date-fns/differenceInMinutes';

/**
 * Token constants.
 */
const tokenKey = `Token${window.environment.ENVIRONMENT}`;
const domain =
    window.location.href.indexOf('localhost') > 0
        ? 'localhost'
        : '.' +
          window.location.origin
              .split('.')
              .slice(-2)
              .join('.')
              .replace(/(.*)(:.*)/, '$1'); // ex: '.carecontinuity.com'

/**
 * Returns the token key for the current environment.
 * @returns {string}
 */
export const getTokenKey = () => tokenKey;

/**
 * Sets the cookie token for the current domain.
 * @param {string} token
 */
export const setCookieToken = (token, tokenKeyOverride) => {
    Cookies.set(tokenKeyOverride ?? tokenKey, token, { domain });
};

export const deleteCookieToken = (tokenKeyOverride) => {
    Cookies.remove(tokenKeyOverride ?? tokenKey);
};

/**
 * Returns the cookie domain.
 * @returns {string}
 */
export const getCookieDomain = () => domain;

/**
 * For the provided token, returns the token expiration time in milliseconds.
 * @param {string} token
 * @returns {number}
 */
export const getJwtTokenExpireMillis = (token) => {
    if (!!token) {
        return jwtDecoder(token).exp * 1000 - new Date();
    } else {
        console.log('Cannot decode JWT token: No token provided');
    }
};

/**
 * Returns the current token expiration time in minutes.
 * @returns {number}
 */
export const getTokenExpiryInMin = (tokenKeyOverride) => {
    const cookieToken = Cookies.get(tokenKeyOverride ?? tokenKey, { domain });
    if (!cookieToken || cookieToken === 'undefined') {
        return null;
    }
    const { exp } = decodeToken(cookieToken);

    return differenceInMinutes(new Date(exp * 1000), new Date());
};

/**
 * Decodes the string token into a structured object.
 * @param {string} jwt - string token
 * @param {Object} options
 * @param {Object} options.complete - if true, returns header object
 * @returns {Object} returns either the parsed payload or the parsed header if options.complete is true
 */
export const decodeToken = (jwt, options) => {
    options = options || {};
    var decoded = jws.decode(jwt, options);
    if (!decoded) {
        return null;
    }
    var payload = decoded.payload;

    //try parse the payload
    if (typeof payload === 'string') {
        try {
            var obj = JSON.parse(payload);
            if (obj !== null && typeof obj === 'object') {
                payload = obj;
            }
        } catch (e) {}
    }

    //return header if `complete` option is enabled.  header includes claims
    //such as `kid` and `alg` used to select the key within a JWKS needed to
    //verify the signature
    if (options.complete === true) {
        return {
            header: decoded.header,
            payload: payload,
            signature: decoded.signature,
        };
    }
    return payload;
};

/**
 * Returns the current token from a browser cookie for the current domain.
 * @returns {string}
 */
export const tokenResolver = (tokenKeyOverride) => {
    const cookieToken = Cookies.get(tokenKeyOverride ?? tokenKey, { domain });
    return cookieToken;
};

/**
 * Returns true if a token exists and has not expired, otherwise false.
 * @returns {boolean}
 */
export const checkValidTokenExists = () => {
    const token = tokenResolver();
    if (!token) return false;
    let expirationInMs;
    try {
        const { exp } = decodeToken(token);
        expirationInMs = exp;
    } catch {
        console.warn('checkValidTokenExists: invalid token.');
        return false;
    }
    const isValid = new Date(expirationInMs * 1000) > new Date();
    return isValid;
};

/**
 * Returns the user ID from the cookie token if it exists.
 * @returns {number}
 */
export const getUserIdFromToken = () => {
    const cookieToken = tokenResolver();
    if (!cookieToken) {
        console.error(`No "${tokenKey}" key found in cookie for domain "${domain}"`);
        return null;
    }
    return decodeToken(cookieToken)?.LoggedInUserId;
};
